import React from "react"
import Layout from "../components/layout.component"
import {RichText} from "prismic-reactjs"
import { linkFragment, linkResolver } from "../link-resolver"
import Header from "../components/header.component"
import { Image } from "../components/common/image.component"
import { graphql } from "gatsby"
import { Slices } from "../components/slices.component"
import SEO from "../components/common/seo.component"


const SuccessStory = ({uri, data}) => {
  const page = data.prismic.allSuccess_storys.edges
    .find(item => {
      return uri === linkResolver(item.node._meta);
    })
  if(page) {
    const { text_content, title, image } = page.node;
    return (
      <Layout>
        <SEO
          title={page.node.page_title || page.node.title}
          description={page.node.description || page.node.text}
          keywords={page.node.keywords}
          image={page.node.preview_image}
          lang={page.node._meta.lang}
        />
          <div className="container">
              <Header theme="light"/>
          </div>
        <div className="container pt-3">
          <div className="text-dark-blue text-center text-success-story mb-5">
            <RichText render={title}/>
          </div>
          <Image className="text-center image-success-story" image={image} />

          <div className="blog-post-content">
            <RichText render={text_content} linkResolver={linkResolver}/>
          </div>
        </div>
        <Slices body={page.node.body} />
      </Layout>
    )
  }
  return null;
};

SuccessStory.fragments = [linkFragment];

export default SuccessStory;

export const query = graphql`
  query successStoryQuery {
  prismic {
    allSuccess_storys {
      edges {
        node {
            page_title
            description
            keywords {
                keyword
            }
            preview_image
          _meta {
            uid
            type
            lang
          }
          image
          text_content
          title
            body {
                ... on PRISMIC_Success_storyBodyGallery {
                    type
                    label
                    primary {
                        anchor
                        bgColor
                        bgImage
                        text
                        title
                    }
                    fields {
                        image
                        text
                        title
                    }
                }
                ... on PRISMIC_Success_storyBodyPricing_plans {
                    type
                    label
                    fields {
                        price_units
                        plan_price
                        plan_name
                        plan_features
                        link_text
                        link_style
                        link {
                            ...link
                        }
                        is_free_plan
                    }
                    primary {
                        bg_image
                        bg_color
                        title
                        text
                    }
                }
                ... on PRISMIC_Success_storyBodyText {
                    type
                    label
                    primary {
                        text
                        bg_color
                        bg_image
                    }
                }
                ... on PRISMIC_Success_storyBodyFeature {
                    type
                    label
                    primary {
                        bg_color
                        bg_image
                        text
                    }
                    fields {
                        image
                        link_style
                        link_text
                        text
                        title
                        link {
                            ...link
                        }
                    }
                }
                ...on PRISMIC_Success_storyBodyBlock_with_text_and_image {
                    label
                    type
                    primary  {
                        bg_color
                        bg_image
                        min_height
                        title
                        text
                        image
                        link {
                            ...link
                        }
                        link_style
                        link_text
                    }
                }
                ... on PRISMIC_Success_storyBodyForm {
                    type
                    label
                    primary {
                        bg_color
                        bg_image
                        form_script
                        form_url
                        text
                    }
                }
            }
        }
      }
    }
  }
}
`
